<template>
  <div class="csn-game">
    <GameLists
      :getList="getTopGameList"
      :itemsPerScreen="itemsPerScreen"
      :hasVericalUpperRow="true"
    />
  </div>
</template>

<script>
import { TOP_GAMES } from '@/constants'
import { cardsPerScreenCalculationMixin } from '@/mixins'
import { GameApi } from '@/api'

export default {
  name: TOP_GAMES,
  mixins: [cardsPerScreenCalculationMixin],
  components: {
    GameLists: () => import('@/components/GameLists'),
  },
  methods: {
    getTopGameList: GameApi.getTopGameList,
  },
}
</script>
